import React, { useEffect } from "react";
import SevenDaysBanner from "../components/SevenDaysBanner";
import DefaultHero from "../components/DefaultHero";
import PriceCalculationButton from "../components/PriceCalculator/PriceCalculationButton";
import Alarmanlagen from "../components/Alarmanlagen";
import Footer from "../components/Footer";
import OurTeam from "../components/OurTeam";
import { StaticQuery, graphql } from "gatsby";
import HowTo2 from "../components/HowTo2";

import NewHome_We_Secure from "../components/NewHome_We_Secure";
import NewHome2 from "../components/NewHome2";
import NewHome3 from "../components/NewHome3";
import FaceToFace from "../components/FaceToFace";
import IntrusionAtlas from "../components/IntrusionAtlas";
import Testimonials from "../components/Testimonials";
import OurPartner from "../components/OurPartner";
import FloorPlan from "../components/FloorPlan";
import Kooperationspartner from "./kooperationspartner";

export const HeroElement = () => {
    return (
        <div className="homepage">
            <h1 className="top only-mobile-500px ">
                Ihr kostenloser Alarmanlagen-Preisrechner
            </h1>

            <h1 className="top only-desktop" style={{ color: "white" }}>
                Ihr kostenloser Alarmanlagen-Preisrechner
            </h1>
            <span
                className="middle only-mobile-500px "
                style={{ marginTop: "-20px", color: "#193351" }}
            >
                In nur wenigen Minuten Preis berechnen
                {/* In wenigen Minuten zur Preisindikation */}
            </span>
            <span
                className="middle only-desktop"
                style={{ marginTop: "-20px", color: "white" }}
            >
                In nur wenigen Minuten Preis berechnen
                {/* In wenigen Minuten zur Preisindikation */}
            </span>
            {/* <PriceCalculationButton id="btn_bighero_preisberechnen" /> */}
        </div>
    );
};

export default () => {
    if (typeof window != `undefined`) {
        sessionStorage.removeItem("onPriceCheckout");
    }
    console.log(" index layout fired with ", Math.random() * 1000);
    // useEffect(() => {
    //     // Chat is displayed after scrolling for 300 pixels
    //     if (typeof window !== "undefined") {
    //         setTimeout(() => {
    //             if (document.getElementById("xenioo-chat-widget")) {
    //                 document.getElementById(
    //                     "xenioo-chat-widget"
    //                 ).style.display = "none";
    //             }
    //         }, 1000);
    //         const handleScrollChatVisiblity = () => {
    //             // window.pageYOffset > 800 ? setShowChat(true) : setShowChat(false);
    //             console.log(
    //                 "handleScrollChatVisiblity fired with ",
    //                 window.pageYOffset
    //             );
    //             if (
    //                 document.getElementById("xenioo-chat-widget") &&
    //                 document.getElementsByClassName("price-box")
    //             ) {
    //                 if (document.getElementsByClassName("price-box")) {
    //                     if (
    //                         window.pageYOffset >
    //                         document.getElementsByClassName("price-box")[0]
    //                             .clientHeight +
    //                             200
    //                     ) {
    //                         document.getElementById(
    //                             "xenioo-chat-widget"
    //                         ).style.display = "block";
    //                     }
    //                 } else {
    //                     document.getElementById(
    //                         "xenioo-chat-widget"
    //                     ).style.display = "none";
    //                 }
    //             }
    //         };

    //         window.addEventListener("scroll", handleScrollChatVisiblity);
    //         return () => {};
    //         window.removeEventListener("scroll", handleScrollChatVisiblity);
    //     }
    // }, []);
    return (
        <StaticQuery
            query={graphql`
                query HomepageQuery {
                    site {
                        siteMetadata {
                            title
                            image
                            image768
                            image1024
                            image1216
                            image1408
                        }
                    }
                }
            `}
            render={(data) => {
                const contextData = {
                    // title: data.site.siteMetadata.title,
                    image: "/img/hero/" + data.site.siteMetadata.image,
                    // image768: "/img/hero/" + data.site.siteMetadata.image768,
                    image1024: "/img/hero/" + data.site.siteMetadata.image1024,
                    image1216: "/img/hero/" + data.site.siteMetadata.image1216,
                    image1408: "/img/hero/" + data.site.siteMetadata.image1408,
                    // showPriceCalculator: false,
                    showPriceCalculator: true,
                    showHeroMobile: false,
                    showBottom: true,
                    showElement: <HeroElement />,
                    backgroundColor: "whiteTextHero",
                };

                return (
                    <div id="indexMain">
                        <DefaultHero context={contextData} />
                        <NewHome_We_Secure />
                        <NewHome3 />

                        <HowTo2 />
                        <OurPartner />
                        <FloorPlan />
                        <NewHome2 />

                        {/* <FaceToFace />
                        <Testimonials />
                        <OurTeam />
                        <Alarmanlagen /> */}
                        <IntrusionAtlas context={contextData} />
                        <SevenDaysBanner />
                        <Footer />
                    </div>
                );
            }}
        />
    );
};
