import React from "react";
import { Link } from "gatsby";

export default class NewHomeWeSecure extends React.Component {
    render() {
        return (
            <div style={{ marginBottom: "20px" }}>
                {" "}
                <div
                    className="section how-to2 is-hidden-mobile "
                    style={{ paddingTop: "40px" }}
                >
                    <div className="body">
                        <div className="columns">
                            <div className="column has-text-centered">
                                <h2 className="title">
                                    Wir sichern Sie mit unseren hochwertigen
                                    Sicherheitssystemen ab.
                                </h2>
                                <h3>Alles für Ihre Sicherheit.</h3>
                            </div>
                        </div>

                        <div>
                            <div className="columns">
                                <div className="column has-text-centered">
                                    <Link to="/alarmanlagen">
                                        <div
                                            className="card card-homepage"
                                            style={{
                                                border: "4px solid #F2F2F2",
                                            }}
                                            id="btn_syscat_alarmanlage"
                                        >
                                            <div
                                                className="card-title"
                                                style={{
                                                    // backgroundColor: "black",
                                                    backgroundColor: "#F2F2F2",
                                                    // color: "white",
                                                    color: "black",
                                                    paddingTop: "18px",
                                                    paddingBottom: "18px",
                                                    fontSize: "24px",
                                                }}
                                            >
                                                Alarmanlagen
                                            </div>
                                            <div
                                                className="card-image"
                                                style={{ marginTop: "10px" }}
                                            >
                                                <figure className="image">
                                                    <img
                                                        className="newHomeImage img_1"
                                                        src="/img/homepage/Alarmanlagen-Picture.png"
                                                        alt="Alarmgen"
                                                        style={{ width: "65%" }}
                                                    />
                                                </figure>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="column has-text-centered">
                                    <Link to="/videoueberwachung/">
                                        <div
                                            className="card card-homepage"
                                            style={{
                                                border: "4px solid #F2F2F2",
                                            }}
                                            id="btn_syscat_video"
                                        >
                                            <div
                                                className="card-title"
                                                style={{
                                                    backgroundColor: "#F2F2F2",
                                                    color: "black",
                                                    paddingTop: "18px",
                                                    paddingBottom: "18px",
                                                    fontSize: "24px",
                                                }}
                                            >
                                                Videoüberwachung{" "}
                                            </div>

                                            <div
                                                className="card-image"
                                                style={{ marginTop: "10px" }}
                                                // style={{ marginTop: "30px" }}
                                            >
                                                <figure className="image">
                                                    <img
                                                        // width="210px"
                                                        className="newHomeImage img_2"
                                                        style={{
                                                            width: "25rem !important",
                                                        }}
                                                        src="/img/homepage/Videoüberwachung-Picture12.png"
                                                        alt=""
                                                    />
                                                </figure>
                                            </div>
                                        </div>
                                    </Link>{" "}
                                </div>
                                <div className="column has-text-centered">
                                    <Link to="/schliessanlagen/">
                                        <div
                                            className="card card-homepage"
                                            style={{
                                                border: "4px solid #F2F2F2",
                                            }}
                                            id="btn_syscat_doorlock"
                                        >
                                            <div
                                                className="card-title"
                                                style={{
                                                    backgroundColor: "#F2F2F2",
                                                    color: "black",
                                                    paddingTop: "18px",
                                                    paddingBottom: "18px",
                                                    fontSize: "24px",
                                                }}
                                            >
                                                Schließanlagen
                                            </div>
                                            <div
                                                className="card-image"
                                                style={{ marginTop: "10px" }}
                                            >
                                                <figure
                                                    className="image"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        marginTop: "38%",
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            width: "250px",
                                                            height: "110px",
                                                            // marginTop: "70px",
                                                        }}
                                                        className="newHomeImage img_3"
                                                        src="/img/homepage/Schließanlagen-Picture.png"
                                                        alt="Wir installieren für sie"
                                                    />
                                                </figure>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="weSecureMobile only-mobile">
                    <div className="columns">
                        <div className="column has-text-centered">
                            <h2 className="title">
                                Wir sichern Sie mit unseren hochwertigen
                                Sicherheitssystemen ab.
                            </h2>
                            <h3>Alles für Ihre Sicherheit.</h3>
                        </div>
                    </div>

                    <div>
                        <div className="imageRow">
                            <Link to="/alarmanlagen">
                                <div
                                    className="imagecontainer"
                                    id="btn_syscat_alarmanlage_mob"
                                >
                                    <div className="weSecureMobile-col1">
                                        Alarmanlagen
                                    </div>
                                    <div
                                        className="weSecureMobile-col2"
                                        // style={{ marginTop: "10px" }}
                                    >
                                        <img
                                            className="weSecureMobileImage"
                                            src="/img/homepage/Alarmanlagen-Picture.png"
                                            alt="Alarmgen"
                                        />
                                    </div>
                                </div>
                            </Link>
                            <Link to="/videoueberwachung">
                                <div
                                    className="imagecontainer"
                                    id="btn_syscat_video_mob"
                                >
                                    <div className="weSecureMobile-col1">
                                        Videoüberwachung
                                    </div>
                                    <div className="weSecureMobile-col2">
                                        <img
                                            className="weSecureMobileImage"
                                            src="/img/homepage/Videoüberwachung-Picture.png"
                                            alt="Videoüberwachung"
                                        />
                                    </div>
                                </div>
                            </Link>

                            <Link to="/schliessanlagen">
                                <div
                                    className="imagecontainer"
                                    id="btn_syscat_doorlock"
                                >
                                    <div className="weSecureMobile-col1">
                                        Schließanlagen
                                    </div>
                                    <div className="weSecureMobile-col2">
                                        <img
                                            style={{
                                                height: "5rem",
                                            }}
                                            className="weSecureMobileImage"
                                            src="/img/homepage/Schließanlagen-Picture.png"
                                            alt="Schließanlagen"
                                        />
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
